$content-width: 1000px;

@import "minima";

.site-title {
    font-size: 3em;
    letter-spacing: -1px;
    margin-bottom: 0;
    float: left;
    font-weight: 100;
    font-family: 'EB Garamond', serif;

    font-weight: normal;
    color: $grey-color-dark;
    text-decoration: underline;

    &:hover {
        color: $grey-color;
    }
}

.page-content {
    flex: none;

    h1 {
        font-size: 2em;
    }
    h2 {
        font-size: 1.5em;
    }
    h3 {
        font-size: 1.3em;
    }
}

.image-box {
    float: right;
    padding: 10px
}